/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, canonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            baseUrl
            assetsVersion
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const linkCanonical = canonical;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `apple-mobile-web-app-title`,
          content: `GSoftware`
        },
        {
          name: `application-name`,
          content: `GSoftware`
        },
        {
          name: `msapplication-TileColor`,
          content: `#0d0d0d`,
        },
        {
          name: `theme-color`,
          content: `#0d0d0d`
        }
      ].concat(meta)}
      link={[
        linkCanonical ?
        {
          rel: `canonical`,
          href: linkCanonical
        } : {},
        {
          rel: `apple-touch-icon`,
          sizes: `180x180`,
          href: `/apple-touch-icon.png?ver=${site.siteMetadata.assetsVersion}`,
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `32x32`,
          href: `/favicon-32x32.png?ver=${site.siteMetadata.assetsVersion}`
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `16x16`,
          href: `/favicon-16x16.png?ver=${site.siteMetadata.assetsVersion}`,
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  canonical: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string
}

export default SEO;
